import { PathSegment } from "../../PathSegment";
import { Path } from "../Path";
import * as uuid from 'uuid';

function newid(): String {
  return uuid.v4()
}

export const InsertAction="_i"
export const OneToManyCardinality="OneToMany"
export const IdField = '__id'

export function defaultPathInsert(path: Path) : object {
   console.log("path", path)

   let result = defaultPathSegmentInsert( path.segments) as any;
   result['_a'] = InsertAction;
   result[IdField] = newid();
   return result;
}

function defaultPathSegmentInsert(segments: PathSegment[]) : object{
   let result = {} as any
   segments.forEach(segment=> {
           var subTarget: any;
           if (segment.connection?.entity2.valueType) {
               subTarget = "TODOdefaultvalueType"
           } else {
               subTarget = defaultPathSegmentInsert(segment.segments)
           }
          if (OneToManyCardinality===segment.connection?.cardinality) {
            subTarget = [subTarget]
          }
         result[segment.connection?.name as string] = subTarget  
    })
    return result
}