import { Path } from "./domain/Path";
import { Box } from '@mui/system';
import { Button, Typography, TextField } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';


import React, {useState, useEffect} from 'react';
import { DeploymentClient } from "./host-service";
import { Schemat } from "./domain/Schemat";
import { defaultPathInsert, IdField } from "./domain/serialization/PathUtil";
import {modifyInstance, loadInstances} from './host-service'
import { PathDataView } from "./PathDataView";


interface PathClientViewProps {
  onCancel: () => void;
  deploymentClient: DeploymentClient,
  schemat: Schemat
}
export function PathClientView({onCancel, deploymentClient, schemat} : PathClientViewProps) {
 
  const [request, setRequest] = useState("")
  const [updateStatus, setUpdateStatus] = useState("")

  const [tabIndex, setTabIndex] = useState(0);

  //TODO - remove the assumptions from this line
  const path = schemat.snapshots[0].paths.filter(p=>p.id==deploymentClient.id)[0] as Path

  const defaultInsert = () => {
      let insert = JSON.stringify(defaultPathInsert(path), null, 2)
      setRequest(insert)
  }

  const submitChange = () => {
    setUpdateStatus("Updating . . .")
    console.log(`submitting ${request}`)
    let instance = JSON.parse(request) as object
    let instanceId = (instance as any)[IdField] as string
    modifyInstance(schemat.id, schemat.crudClients[0].id /* TODO fix this */, path.id, instanceId, instance)
    .then(result=>setUpdateStatus(result))
  }

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
 
const handleTabChangeChange = (event: React.SyntheticEvent, newValue: number) => {
  setTabIndex(newValue);
};

 return <Box sx={{ flexGrow: 1 }}>
  <Button onClick={onCancel}>{"<-"} Back {"<-"}</Button> 
  
<Typography variant="h6" component="div" sx={{ flexGrow: 1, alignSelf: 'center'  }}> Client deployment:</Typography>      
<Typography variant="body1" component="div" sx={{ flexGrow: 1, alignSelf: 'center'  }}>{deploymentClient.name}</Typography>      
<Typography  variant="body1" component="div" sx={{ flexGrow: 1, alignSelf: 'center'  }}>{deploymentClient.url}</Typography>      




<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  <Tabs value={tabIndex} onChange={handleTabChangeChange} aria-label="basic tabs example">
    <Tab label="Modify" {...a11yProps(0)} />
    <Tab label="View" {...a11yProps(1)} />
  </Tabs>
</Box>

<Box hidden={tabIndex !== 0}>
<Button onClick={defaultInsert}>Default Insert</Button>
<Button>Default Update (needs to refer to an existing item ?!)</Button>
<Button onClick={submitChange}>Execute Change</Button>
<TextField
          id="outlined-multiline-flexible"
          onChange={e => setRequest(e.target.value)}
          label="request"
          multiline
          rows={10}
          fullWidth={true}
          value={request}
        />

</Box>
<Box hidden={tabIndex !== 1}>
<PathDataView crudClient={schemat.crudClients[0]}  path={path}
schemat={schemat}/>
</Box>


<Typography variant="h6" component="div" sx={{ flexGrow: 1, alignSelf: 'center'  }}> {updateStatus}</Typography>      

     </Box>
}