import { Host } from './domain/admin/Host';
import React, {useState, useEffect} from 'react';
import { Box } from '@mui/system';
import { Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import {Deployment, DeploymentClient, getSchemats, SchematSummary} from './host-service'
import DirectDeploySelector from '././DirectDeploySelector';
import { Schemat } from './domain/Schemat';
import { SchematDeserializer } from './domain/serialization/SchematDeserializer';
import { PathClientView } from './PathClientView';


interface DeploymentDialogProps {
  onCancel: () => void;
  host: Host
}
export function HostView({onCancel, host} : DeploymentDialogProps) {

//DirectDeploySelector
  const [schemats, setSchemats] = useState([] as SchematSummary[]);
  const [selectedSchemat, setSelectedSchemat] = useState(null as SchematSummary | null);
  const [inDeployment, setInDeployment] = useState(false);
  const [selectedDeploymentClient, setSelectedDeploymentClient] = useState(null as DeploymentClient | null);

useEffect(() => {
  //TODO load host
  getSchemats().then(  result=>setSchemats(result) )
  }, []);

  const onCancelDirectDeployment = () => {setInDeployment(false)}

  const onDeploymentComplete = () => {
    setSelectedSchemat(null)
    setInDeployment(false)
    getSchemats().then(  result=>setSchemats(result) )
  }

return <Box sx={{ flexGrow: 1 }}>  

<Button onClick={onCancel}>{"<-"} Back {"<-"}</Button> 

<Typography variant="h5" component="div" sx={{ flexGrow: 1, alignSelf: 'center'  }}>
      Host: {host.name}
          </Typography>      


<Button onClick = { () => setInDeployment(!inDeployment)  }> Deploy Update / New Schemat </Button>
<Button onClick={() => alert('todo')}> New Git Project </Button>

  {inDeployment && <DirectDeploySelector host={host} onDeploymentComplete={onDeploymentComplete} onCancel={onCancelDirectDeployment}></DirectDeploySelector>}

 

  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Id</TableCell>
          <TableCell align="left">Name</TableCell>
          <TableCell align="left">AuthorId</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
           schemats.map((s) => rowUI(s, setSelectedSchemat))
        }
      </TableBody>
    </Table>
  </TableContainer>


{ selectedSchemat &&  schematUI(selectedSchemat, selectedDeploymentClient, setSelectedDeploymentClient)}

</Box>

}

function schematUI(selectedSchemat: SchematSummary, selectedDeploymentClient: DeploymentClient | null, setSelectedDeploymentClient: (path: DeploymentClient | null) => void) :JSX.Element {
let deployment = selectedSchemat.deployment && JSON.parse(selectedSchemat.deployment) as Deployment
console.log("deployment", deployment)

let schemat = selectedSchemat.schemat && new SchematDeserializer().read(selectedSchemat.schemat)
if (schemat!=null) console.log(`schemat`, schemat)

function clearSelectedDeploymentClient() {
  setSelectedDeploymentClient(null)
}

return <Box>
   {!selectedDeploymentClient && 
<TableContainer component={Paper}>
<Table sx={{ minWidth: 100 }} size="small" aria-label="simple table">
  <TableHead>
    <TableRow>
      <TableCell align="left">Name:</TableCell>
      <TableCell align="left">{selectedSchemat.schematName}</TableCell>
    </TableRow>
    <TableRow>
      <TableCell align="left">CreateTime:</TableCell>
      <TableCell align="left">{selectedSchemat.creationTime}</TableCell>
    </TableRow>
  
    <TableRow>
      <TableCell align="left">LastUpdate:</TableCell>
      <TableCell align="left">{selectedSchemat.lastUpdate}</TableCell>
    </TableRow>
    {
  deployment && 
    <TableRow>
    <TableCell align="left">Deployment:</TableCell>
    <TableCell align="left">{deployment.logEvents.map(l=>l.message).join(",")}</TableCell>
  </TableRow>
  }
  {
    deployment && clientUI(deployment, schemat as Schemat, setSelectedDeploymentClient)
  }

  </TableHead>
</Table>
</TableContainer>
}
{selectedDeploymentClient && <PathClientView deploymentClient={selectedDeploymentClient}
        onCancel={clearSelectedDeploymentClient} schemat={schemat as Schemat}/>}

</Box>
}

function clientUI(deployment: Deployment, schemat: Schemat, setSelectedDeploymentClient: (path: DeploymentClient | null) => void) : JSX.Element {
  console.log("clientUI: deployment", deployment)



  return <React.Fragment>
    {
      
deployment.clients.map (c=> {
  const onClick= () => {
    console.log("", c)    
    //setSelectedPath(c.id)
  }
//  const path = schemat.snapshots[0].paths.filter(p=>(p.id==c.id))[0]

  const selectDeploymentClient = () => {
 setSelectedDeploymentClient(c)
}

  return <TableRow onClick={selectDeploymentClient}>
        <TableCell align="left">Deployment Client:</TableCell>
        <TableCell align="left">{c.name}: {c.url} </TableCell>
  </TableRow>
})}
  
  </React.Fragment>

}


function rowUI(s: SchematSummary, select: (selected: SchematSummary) => void) : JSX.Element {

            let localSelect = () => select(s)     
            return (<TableRow hover 
            key={s.id}
            onClick = {localSelect}        
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="left" component="th" scope="row">
              {s.id}
            </TableCell>
            <TableCell align="left" component="th" scope="row">
              {s.schematName}
            </TableCell>
            <TableCell align="left">{s.authorId}</TableCell>


          </TableRow>)
}