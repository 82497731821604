import { Input, InputLabel, Button, Typography } from '@mui/material';
import React from 'react';
import DeploymentDialog from './DeploymentDialog';
import { Host } from './domain/admin/Host';



interface DirectDeploySelectorParams {
  onCancel: () => void;
  onDeploymentComplete: () => void;
  host: Host
}

export  function DirectDeploySelector({onCancel, onDeploymentComplete, host} : DirectDeploySelectorParams) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [filename, setFilename] = React.useState("");
  const [fileContents, setFileContents] = React.useState("N/A");

  const onInputChange = (c: React.ChangeEvent<HTMLInputElement>) => {
    console.log("hello ",c)
  const fileObj = c.target.files && c.target.files[0];
  if (!fileObj) {
    return;
  }
  fileObj.text().then (str=>
    {
     setFileContents(str);
       setFilename(fileObj.name)
      setOpenDialog(true)
 
        }
  );
 }
 const styles = {
  hidden: {
    display: "none",
  },
  importLabel: {
    color: "black",
  },
};

function fileContentsSummary() : string{
  return !fileContents ? "" : fileContents.substring(0, 100) + " . . ." 
}

  return (
  <React.Fragment>
  <Button onClick={onCancel}>{"<-"} Cancel Direct Deployment {"<-"}</Button> 
  <InputLabel  htmlFor="import-button" style={styles.importLabel}>
  <Input 
  id="import-button"
  inputProps={{
    accept:".schemat"
  }}

  onChange={(c)=>{onInputChange(c as React.ChangeEvent<HTMLInputElement>)}}
  style={styles.hidden}
  value= {""/* not allowed for security reasons "c:\\temp\\test.txt"*/}
  type={"file"}
/>
Click to Select And Deploy to {host.name}
<DeploymentDialog open={openDialog} setOpen={setOpenDialog} onDeploymentComplete={onDeploymentComplete} filename={filename} filecontents={fileContents} host={host}></DeploymentDialog>
</InputLabel>
<Typography variant="h5" component="div" sx={{ flexGrow: 1, alignSelf: 'center'  }}>
       {fileContentsSummary()} 
          </Typography>
</React.Fragment>  )
}

export default DirectDeploySelector
