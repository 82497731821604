import { Box } from '@mui/system';
import { Typography } from '@mui/material';

export default function Todo() {
  return <Box>


           <Typography variant="h5" component="div" sx={{ flexGrow: 1, alignSelf: 'center'  }}>
      TODO: link clients to utilities e.g. data view, show roles, download sample clients
          </Typography>      
          <Typography variant="h5" component="div" sx={{ flexGrow: 1, alignSelf: 'center'  }}>
      TODO: implement clients s (1) amend (2) search (3) get (initially at basic---swagger--level - provide sample jsons)
          </Typography>       
          <Typography variant="h5" component="div" sx={{ flexGrow: 1, alignSelf: 'center'  }}>
      TODO: implement optimistic client concurrency - client sends from and to, if any froms dont match transaction isnt accepted 
          </Typography>  
          <Typography variant="h5" component="div"   sx={{ flexGrow: 1, alignSelf: 'center', textDecoration: "line-through" }}>
      TODO: deserialize Schemat in admin app
          </Typography>          
  </Box>
}