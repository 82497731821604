import React, {useState, useEffect} from 'react';
import { Schemat } from './domain/Schemat';
import { CrudClient } from './domain/CrudClient';
import { Path } from './domain/Path';
import {modifyInstance, loadInstances, LoadInstancesResult} from './host-service'
import { Button, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { PathSegment } from './PathSegment';

interface PathDataViewProps {
//  onCancel: () => void;
  schemat: Schemat,
  crudClient: CrudClient,
  path: Path
}


export function PathDataView({/*onCancel,*/ schemat, crudClient, path} : PathDataViewProps)  {

  const [updateStatus, setUpdateStatus] = useState("")
  const [results, setResults] = useState(null as (LoadInstancesResult | null))

 

  const loadInstancesLocal = () => {
    setUpdateStatus("Loading . . .")
    setResults(  null )
    //loadInstances(schemat.id, schemat.crudClients[0].id /* TODO fix this */, path.id)
    loadInstances(schemat.id, crudClient.id /* TODO fix this */, path.id)
    .then(result=> {
        console.log('result', result)
        result.items = result.items.map(item=>JSON.parse(item))
        setResults(result)
        setUpdateStatus(`Load complete -${result.items?.length} items`);
    })
  }

  /*useEffect(() => {
    loadInstancesLocal()
    }, []); 
*/

return <div><Button onClick={loadInstancesLocal}>Reload</Button> {updateStatus}    

<div>
{results != null && itemsTable(results, path) }
</div>

</div>  

}


function itemsTable(results: LoadInstancesResult, path: Path) : JSX.Element {
  console.log("itemsTable result:", results);
  return  <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead>
      <TableRow>
{
  path.segments.map(seg=><TableCell align="left">{seg.connection?.name}</TableCell>)
}
      </TableRow>
    </TableHead>
    <TableBody>
      {
      results.items.map((item) => itemRow(item, path))
      }
    </TableBody>
  </Table>
</TableContainer>
} 


function itemRow(item: object, path: Path) : JSX.Element {
    return <TableRow>{path.segments.map (seg=>cell(item, seg))}</TableRow>
}

function itemAsString(item: object, name: string) {
  let theval = (item as any)[name]
  if (Array.isArray(theval) ) {
         return `${theval.length} items`
  } else if ((typeof theval) === 'object' ) {
       let id = theval["__id"]

       return `${ id ? id : name   }`
  }
  return `${theval}`
}


function cell(item: object, seg: PathSegment) : JSX.Element {
     return <TableCell align="left">{itemAsString(item, seg.connection?.name as string)}</TableCell>
}