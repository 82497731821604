import './App.css';
import { withAuthenticator, Button, WithAuthenticatorProps  } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import HostsTable from './HostsTable';
import Todo from './Todo';
import { Box } from '@mui/system';
import { AppBar, IconButton, MenuItem, Toolbar, Typography } from '@mui/material';
import entityImage from './images/entity.svg';
//import { useSearchParams } from "react-router-dom";
import React, {useState} from 'react';



function App(props: WithAuthenticatorProps) {
   

const params = new URLSearchParams( window.location.search);

  //const [searchParams, setSearchParams] = useSearchParams();
const localFileName = params.get("localFilename")

const [showToDo, setShowToDo] = useState(false as boolean);



  const onHome = () => {
    //setSelected(null);
  //  console.log('home');
  }
  
  const onTodo = () => {
    setShowToDo(!showToDo)
    //setSelected(null);
  //  console.log('home');
  }
  

  return (

<Box sx={{ flexGrow: 1 }}>  
<AppBar position="static" >
<Toolbar>
<Box display='flex' >
            {/* whatever is on the left side */}
            <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="Home"
                onClick={onHome}
                sx={{ml:1}}
              >
                <img width="40px" src={entityImage} alt={"love Schemact"}/> 
                
              </IconButton>
        </Box>
        <Typography variant="h3" component="div" sx={{ flexGrow: 1, alignSelf: 'center'  }}>
        Schemact Admin {localFileName}
          </Typography>
          <MenuItem onClick={onTodo}> Todo</MenuItem>

        <MenuItem onClick={onHome}> Home</MenuItem>
        <Button onClick={props.signOut}>Sign out</Button>

        </Toolbar>
</AppBar>

{!showToDo && <HostsTable/>}
{showToDo && <Todo></Todo>}

</Box> 


  );
}

export default withAuthenticator(App);
