export interface Host {
   id: string,
   name: string,
   url: string,
   gitRepo: string,
   gitBranch: string,
   gitPath: String,
   canDeployDirect: boolean

}

export function sampleHosts() : Host[] {
   return [{id:"123", name: "shareddev", 
              url:"https://schemact3shareddevapi.testedsoftware.org",
            gitRepo:"git@github.com:typedpath/sample.git",
            gitBranch:"dev",
           gitPath: "domain/myorg.schemact",
           canDeployDirect: true},
           {id:"124", name: "sharedqa", 
           url:"https://schemactapi.org/sharedhost/qa",
           gitRepo:"git@github.com:typedpath/sample.git",
           gitBranch:"qa",
           gitPath: "domain/myorg.schemact",
           canDeployDirect: false}
          ]
}