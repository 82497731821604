import { Path } from "../Path";
import { Entity } from "../Entity";
import { Schemat } from "../Schemat";
import { Connection } from "../Connection";
import { PathSegment } from "../../PathSegment";
import { DataModel } from "../DataModel";
import { CrudClient } from "../CrudClient";

export class SchematDeserializer {

  read(json: string) : Schemat {
    let oSchemat = JSON.parse(json)
    console.log("SchematDeserializer::read", oSchemat)
    let oDataModels = oSchemat["snapshots"] as []
    oDataModels.forEach(oDataModel => {
      this.rereferenceDataModel(oDataModel)
    })
    let id2DataModel = new Map<string, DataModel>();
    (oSchemat as Schemat).snapshots.forEach(dataModel => {
      id2DataModel.set(dataModel.id, dataModel);
    });
    (oSchemat["crudClients"] as []).forEach(oCrudClient=>{
      this.rereferenceCrudClient(oCrudClient, id2DataModel);
    })
    
    return oSchemat as Schemat  
  }

  rereferenceCrudClient(oCrudClient: any, id2DataModel: Map<string, DataModel>) {
    let dataModelId = oCrudClient["dataModel"]
    let dataModel = id2DataModel.get(dataModelId)
    if (!dataModel) throw new Error(`cant resolve crudClient dataModel: ${dataModelId}`);
    (oCrudClient as CrudClient).dataModel = dataModel
  }  


  rereferenceDataModel(oDataModel: any) {
     let idToEntity = new Map<string, Entity>()
     let oEntities = oDataModel["entities"] as []
     oEntities.forEach(oEntity=> {
      idToEntity.set(oEntity["id"] as string, oEntity as Entity)
     })
     this.rereferenceConnectionEntities(oEntities, idToEntity )
     let oPaths = oDataModel["paths"]

     let id2Connection = new Map<string, Connection>()
     for (let ent of Array.from(idToEntity.values())) {
          ent.connections.forEach(connection=>{
             id2Connection.set(connection.id, connection)
          })
     }

     this.rereferencePaths(oPaths, idToEntity, id2Connection);
  } 

private rereferencePaths(oPaths: any[], idToEntity: Map<string, Entity>, id2Connection: Map<string, Connection> ) {
    oPaths.forEach(oPath=>{
      let rootId = oPath["root"] as string;
      if (!rootId) {
        console.log(`WTF path has empty root`, oPath);
        return;
      }
      let root = idToEntity.get(rootId);
      if (root ==null) {
          throw new Error(`path root entity ${rootId}`);
      }
      (oPath as Path).root = root
      this.referenceSegmentConnections(oPath["segments"] as any[], id2Connection)
    })
}


private rereferenceConnectionEntities(oEntities: any[], idToEntity: Map<string, Entity> ) {
  oEntities.forEach(oEntity=> {
    let oConnections = oEntity["connections"] as any as []
    oConnections.forEach( (oConnection) => {
         let ent1Current = oConnection["entity1"]
           if (!ent1Current["id"]) {
           (oConnection as object as any)["entity1"] = idToEntity.get(ent1Current) 
         }
         let ent2Current = oConnection["entity2"]
           if (!ent2Current["id"]) {
           (oConnection as object as any)["entity2"] = idToEntity.get(ent2Current) 
         }
       })   
  })
}

private referenceSegmentConnections(oSegments: any[], idToConnection: Map<string, Connection>) {
      oSegments.forEach(oSegment=> {
          let connectionId = oSegment["connection"]
          let connection = idToConnection.get(connectionId)
          if (connection ==null)
          {
                //console.log('connection search (${idToConnection}) failed in ', idToConnection)
                let strId2Connection = Array.from(idToConnection.keys()).join(",")
                throw new Error(`cant find segment connnection ${connectionId} in ${strId2Connection}`);
          }
          (oSegment as PathSegment).connection = connection;
          this.referenceSegmentConnections(oSegment["segments"], idToConnection);
      })
}



}
