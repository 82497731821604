import { Auth, API } from "aws-amplify";

export async function directUploadUrl() :Promise<String> {
  const apiName = "shareddev";
  const path = "/directUploadUrl";

  const myInit = { 
    headers: { 
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    }
  };

  return await API.post(apiName, path, myInit).then(
    (result) => {   
        return result as String;
    }
)
 }

 export async function directUpload(spec: String) :Promise<String> {
  const apiName = "shareddev";
  const path = "/directUpload";

  const myInit = { 
    headers: { 
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: spec,
  };

  return await API.post(apiName, path, myInit).then(
    (result) => {   
        return result as String;
    }
)
 }

export interface SchematSummary {
  id: string,
  authorId: string,
  email: string,
  schematName: string,
  creationTime: string,
  schemat: string,
  lastUpdate: string,
  deployment: string
}
/*  "deployment" : "{\n  \"logEvents\" : [ {\n    \"isoDateTime\" : \"2023-05-22T09:03:05.294498\",\n  
  \"message\" : \"autodeployed hello 4fb331fa-2128-4d00-8264-63d3cc92b86f \"\n  } ],\n 
   \"clients\" : [ {\n    \"id\" : \"a938497b-facc-4167-9f3f-1c6c3e9b7bb0\",\n   
    \"name\" : \"newDataClient/newPath2\",\n    \"description\" : \"null/null\",\n  
      \"url\" :
       \"https://schemact3shareddevapi.testeds
       oftware.org/instances/4fb331fa-2128-4d00-8264-63d3cc92b86f/90b6b0fa-2ae3-4d48-9a2b-ab3b45a20b75/a938497b-facc-4167-9f
       f-1c6c3e9b7bb0\",\n    \"paths\" : [ ]\n  }, {\n    \"id\" : \"97cbb73b-6457-4b37-ac24-e43e7d2d9612\",\n   
        \"name\" : \"newDataClient/newPath\",\n    \"description\" : \"null/null\",\n    \"url\" : \"https://schemact3shareddevapi.testedsoftware.org/instances/4fb331fa-2128-4d00-8264-63d3cc92b86f/90b6b0fa-2ae3-4d48-9a2b-ab3b45a20b75/97cbb73b-6457-4b37-ac24-e43e7d2d9612\",\n    \"paths\" : [ ]\n  }, {\n    \"id\" : \"4a0a7e4e-24aa-4ce4-bd1c-c3231c393a5a\",\n    \"name\" : \"newDataClient/path3\",\n    \"description\" : \"null/null\",\n    \"url\" : \"https://schemact3shareddevapi.testedsoftware.org/instances/4fb331fa-2128-4d00-8264-63d3cc92b86f/90b6b0fa-2ae3-4d48-9a2b-ab3b45a20b75/4a0a7e4e-24aa-4ce4-bd1c-c3231c393a5a\",\n    \"paths\" : [ ]\n  } ]\n}"
*/

export interface DeploymentLogEvent { message: string, isDateTime: string}
export interface DeploymentClient { id: string, name: string, description: string, url: string}

export interface Deployment {
    logEvents: DeploymentLogEvent[]
    clients: DeploymentClient[]
}



 export async function getSchemats() :Promise<SchematSummary[]> {
  const apiName = "shareddev";
  const path = "/schematsByAuthorId";

  const myInit = { 
    headers: { 
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    }
  };

  return await API.get(apiName, path, myInit).then(
    (result) => {   
        return result as SchematSummary[];
    }
)
 }

 export async function modifyInstance(schematId: string, clientId: string, pathId: string, instanceId: string, updateSpec: object) :Promise<string> {
  const apiName = "shareddev";
  const path = `/instanceModify/${schematId}/${clientId}/${pathId}/${instanceId}`;
  /*https://schemact3shareddevapi.testedsoftware.org/instanceModify/
  4fb331fa-2128-4d00-8264-63d3cc92b86f/
  90b6b0fa-2ae3-4d48-9a2b-ab3b45a20b75/
  4a0a7e4e-24aa-4ce4-bd1c-c3231c393a5a/
  6251f107-f3fd-46a1-853c-2ab5a7018c2f 
  */
  const myInit = { 
    headers: { 
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: JSON.stringify(updateSpec)
  };

  return await API.post(apiName, path, myInit).then(
    (result) => {  
        console.log(`result `, result) 
        return result as string;
    }
).catch(error=> {
    console.log(`error`, error)
    console.log(`, error.message`, error.message)
  
    return `${error}`
})
 }

 export interface LoadInstancesResult {
     nextPageStart: string | null,
     items: any[] ,
     error: string
 }


 export async function loadInstances(schematId: string, clientId: string, pathId: string) :Promise<LoadInstancesResult> {
  const apiName = "shareddev";
  const page = "page"
  const path = `/instanceLoad/${schematId}/${clientId}/${pathId}/${page}`;
  console.log("path", path)

  const myInit = { 
    headers: { 
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    }
  };

  return await API.get(apiName, path, myInit).then(
    (result) => {  
      console.log('result', result)
        let typedResult = result as LoadInstancesResult
        typedResult.items=typedResult.items.map(item=>JSON.parse(item))
        return typedResult;
    }
).catch(error=> {
    console.log(`error`, error)
    console.log(`, error.message`, error.message)
    return {error: error.message as string, items: [], nextPageStart: null} as LoadInstancesResult
})
 }