import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Host, sampleHosts } from './domain/admin/Host';
import {HostView} from './HostView'
import { Button } from '@mui/material';
import React, {useState} from 'react';

interface HostTableState {
  selectedHost: Host | null;
}

export function HostsTable() {
  const [tableState, setTableState] = useState({selectedHost: null} as HostTableState);

  var hosts = sampleHosts()

  function onSelectHost(host: Host | null) {
      setTableState({selectedHost: host})
  }

  function cancelSelected() {
    setTableState({selectedHost: null})
}

  return (
    <React.Fragment>
    { !(tableState.selectedHost) && <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align="left">Id</TableCell>
          <TableCell align="left">Name</TableCell>
          <TableCell align="left">Url</TableCell>
          <TableCell align="left">Repo</TableCell>
          <TableCell align="left">Branch</TableCell>
          <TableCell align="left">Path</TableCell>
          <TableCell align="left">Git Deploy</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
        
           hosts.map((row) => rowUI(row, onSelectHost))
        }
      </TableBody>
    </Table>
  </TableContainer>}
  { tableState.selectedHost && <HostView host={tableState.selectedHost} onCancel={cancelSelected}></HostView>}
  </React.Fragment> 
  );
        }

        function rowUI( row: Host, onSelectHostIn: (hostClicked: Host)=> void) : JSX.Element {
                 function onSelectHost() {onSelectHostIn(row)}

                 return (<TableRow hover onClick={onSelectHost}
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="left" component="th" scope="row">
              {row.id}
            </TableCell>
            <TableCell align="left" component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="left">{row.url}</TableCell>
            <TableCell align="left">{row.gitRepo}</TableCell>
            <TableCell align="left">{row.gitBranch}</TableCell>
            <TableCell align="left">{row.gitPath}</TableCell>
            <TableCell align="left">
              
{
          row.canDeployDirect && <Button onClick=  {onSelectHost} >Deploy ! </Button> 

        }
</TableCell>
          </TableRow>)

        }
  
        export default HostsTable
