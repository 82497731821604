import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Host } from './domain/admin/Host';
import {directUpload} from './host-service'


interface DeploymentDialogProps {
  open: boolean;
  setOpen: (val: boolean) => void ;
  onDeploymentComplete: () => void;
  filename: string;
  filecontents: string;
  host: Host
}

export default function DeploymentDialog({open, setOpen, onDeploymentComplete, filename, filecontents, host} : DeploymentDialogProps) {

  const handleClickDeploy = () => {
    console.log("sending: ", filecontents)
    let result = directUpload(filecontents).then(result=>
      {
      console.log(`result:`, result)
      onDeploymentComplete() 
     }
      )
    setOpen(false);
  };

  const handleClickAbort = () => {
    setOpen(false);
  };

  function fileContentsSummary() : string{
     return !filecontents ? "" : filecontents.substring(0, 100) + " . . ." 
  }

  return (
      <Dialog
        open={open}
        onClose={handleClickAbort}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Deploy ${filename}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deploy {filename} to {host.name} @ {host.url} ({fileContentsSummary()})
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickAbort}>Abort Deploy</Button>
          <Button onClick={handleClickDeploy} autoFocus> Deploy Now</Button>
        </DialogActions>
      </Dialog>
  );
}